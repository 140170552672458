<template>
  <div class="title">
    <h2>{{ title }}</h2>
    <div class="wrap-line">
      <div class="line"></div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: String
  }
}
</script>

<style lang="scss" scoped>
@import '@/assets/styles/variable.scss';

.title {
  display: flex;
  flex-direction: column;
  padding: 20px 0;
  width: fit-content;

  h2 {
    font-family: 'DB Ozone X Bd';
    text-align: center;
    font-size: 30px;
    letter-spacing: 1px;

    @media screen and (min-width: 768px) {
      text-align: left;
      font-size: 60px;
    }
  }

  .wrap-line {
    display: flex;
    justify-content: flex-end;
    // width: 100%;

    .line {
      margin: -8px -8% 0 0;
      width: 40%;
      height: 3px;
      background-color: $color-main;

      @media screen and (min-width: 768px) {
        height: 5px;
        margin: -18px -8% 0 0;
      }
    }
  }
}
</style>
